export const Types = {
  LIVE: "live",
  VIRTUAL: "virtual",
};
export enum BrokerId {
  Blofin = 7,
  Alpaca = 4,
  AP = 8,
}
export enum BrokerName {
  Blofin = "blofin",
  Alpaca = "alpaca",
  AP = "antos pinnacles",
}
export interface UserInfo {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    unique_key: string;
    profile_image: string;
    trading_challenge: string;
    description: string;
    is_active: boolean;
    user_type: string;
    meta_data: string;
  };
  preference: {
    country: string;
    trading_yoe: string;
    risk_class: string;
    language: string;
    asset_class: string;
    notification_preference: any;
    nudges_tracker: string;
    walkthrough_status: string;
    webhook: string;
    send_email: string;
  };
  is_old_user: boolean;
  user_id: string;
  hmac: string;
  token: string;
  subscription: string;
  start_date: string;
  redirect_after_login: string;
}

export interface StrategyData {
  strategy_details: {
    id: number;
    keygen: string;
    strategy_name: string;
    symbol: string;
    is_deleted?: number;
    timeframe: string;
    strategy_category?: any;
    add_ons?: string;
    is_copied?: number;
    is_notification?: number;
    backtest_type?: string;
    strategy_data?: string;
    canvas_data?: string;
    created_at: string;
  };
  epoch: string;
  action_metrics: Array<{
    trailling_sl_p: any;
    trailling_sl: any;
    order_type: string;
    node_id: string;
    stop_loss_p: any;
    stop_loss: any;
    size?: string;
    take_profit: any;
    take_profit_pips: any;
    stop_loss_pips: any;
    take_profit_price: any;
    stop_loss_price: any;
    take_profit_p: any;
  }>;
  deployment: {
    is_notification_deployed: boolean;
    is_virtual_deployed: boolean;
    is_live_deployed: boolean;
    live_depl_id: number;
  };
}

export enum DeploymentSize {
  FOREX_STR = "0.01",
  EQUITY_STR = "1",
  FOREX = 0.01,
  EQUITY = 1,
}

export enum TrendingTradersTableDataKey {
  Three = "3",
  Seven = "7",
  Fifteen = "15",
  Thirty = "30",
}

export enum AccentColor {
  GREEN = "#2a9d90",
  RED = "#f87171",
  GRAY = "#e6e6e6",
}

export enum SharpRatioString {
  DEFAULT = "Good",
  BELOW3 = "Poor",
  ABOVE3 = "Excellent",
}
